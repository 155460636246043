@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap";
* {
  box-sizing: border-box;
}

html {
  --nextui-colors-headerBackground: rgba(255, 255, 255, .8);
  font-size: calc(.9em + .5vw);
  line-height: 1.3;
  --nextui-fonts-sans: "General Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
  --nextui-letterSpacings-tighter: -.025em !important;
  --nextui-letterSpacings-tight: 0 !important;
  --nextui-letterSpacings-normal: 0 !important;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 1em;
}

main.main-trade {
  padding-top: 6rem;
}

.greeting {
  color: var(--secondary);
  text-decoration: underline;
}

h2 {
  text-align: center;
}

.please-wait .change {
  pointer-events: none;
}

ul.information {
  text-align: left;
  margin: 2em 0;
  padding: 0;
  font-size: .8em;
}

.information li:first-child {
  border-top: 1px solid var(--light-gray);
}

.information li {
  border-bottom: 1px solid var(--light-gray);
  padding: .5em 0;
  list-style: none;
}

.change {
  border: 2px solid var(--light-gray);
  flex-direction: column;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: stretch;
  padding: .5em;
  font-size: 1em;
  display: flex;
}

.change > div {
  align-content: stretch;
  justify-content: space-evenly;
  align-items: stretch;
  display: flex;
}

.change input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
}

.change label {
  text-align: left;
  margin-right: 10px;
  padding-bottom: .5em;
  display: block;
}

.change button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

a, .link {
  color: var(--primary);
  text-decoration: none;
}

a:hover, a:focus, .link:hover, .link:focus {
  text-decoration: underline;
}

a:active, .link:active {
  color: var(--secondary);
}

button, input {
  font: inherit;
  outline: none;
}

main.please-wait .change button {
  pointer-events: none;
  background-color: #fff;
  position: relative;
}

main.please-wait .change button span {
  visibility: hidden;
}

button {
  background-color: var(--secondary);
  color: #efefef;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .3em .75em;
  transition: -webkit-transform 30ms, transform 30ms;
}

button:hover, button:focus {
  box-shadow: inset 0 0 10em rgba(255, 255, 255, .2);
}

input {
  background-color: var(--light-gray);
  caret-color: var(--primary);
  color: inherit;
  border: none;
  border-radius: 5px 0 0 5px;
  padding: .25em .5em;
}

input::selection {
  background-color: var(--secondary);
  color: #efefef;
}

input:focus {
  box-shadow: inset 0 0 10em rgba(0, 0, 0, .02);
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

.dark-theme {
  --nextui-colors-headerBackground: rgba(0, 0, 0, .5);
}

main.please-wait .loader, main.please-wait .loader:after {
  display: inline-block;
}

.loader, .loader:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: none;
}

.loader {
  text-indent: -9999em;
  border-top: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
  border-left: 3px solid #fff;
  font-size: 10px;
  -webkit-animation: load8 1.1s linear infinite;
  animation: load8 1.1s linear infinite;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button {
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.sign-in--container {
  text-align: center;
  padding-top: 5rem;
}

#near-wallet-selector-modal {
  z-index: 10000;
  position: relative;
}

.header {
  width: 100%;
  z-index: 9000;
  background-color: var(--nextui-colors-headerBackground);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
}

.header .button {
  font-weight: 600;
}

.header--logo {
  margin-right: 5rem;
}

.header--nav {
  align-items: center;
  display: flex;
}

.header--mode-switcher {
  margin-left: auto;
}

#near-wallet-selector-modal {
  --backdrop-bg: rgba(38, 38, 38, .19);
  --heading-color: #222;
  --text-color: #676767;
  --sidebar-border-color: #ededed;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: transparent;
  --wallet-option-border-color: rgba(167, 167, 167, .19);
  --wallet-option-bg-hover: #ededed;
  --content-bg: #fff;
  --change-path-bg: #ededed;
  --home-button-bg: #ededed;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #ededed;
  --close-button-fill-icon-color: #9f9f9f;
  --spinner-color: #676767;
  --bottom-section: #ececec;
  --mobile-text: #3e3e3e;
  --connected-green: #47e586;
}

#near-wallet-selector-modal .dark-theme {
  --backdrop-bg: rgba(38, 38, 38, .19);
  --heading-color: #fff;
  --text-color: #c1c1c1;
  --sidebar-border-color: #313030;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: rgba(38, 38, 38, .8);
  --wallet-option-border-color: rgba(167, 167, 167, .19);
  --wallet-option-bg-hover: #313030;
  --content-bg: #232323;
  --change-path-bg: #161616;
  --home-button-bg: #313030;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #313030;
  --close-button-fill-icon-color: #c1c1c1;
  --spinner-color: #fff;
  --bottom-section: #131313;
  --mobile-text: #c1c1c1;
  --connected-green: #47e586;
}

@media (prefers-color-scheme: dark) {
  #near-wallet-selector-modal {
    --backdrop-bg: rgba(38, 38, 38, .19);
    --heading-color: #fff;
    --text-color: #c1c1c1;
    --sidebar-border-color: #313030;
    --selected-wallet-bg: #4f7cd1;
    --selected-wallet-bg-hover: rgba(38, 38, 38, .8);
    --wallet-option-border-color: rgba(167, 167, 167, .19);
    --wallet-option-bg-hover: #313030;
    --content-bg: #232323;
    --change-path-bg: #161616;
    --home-button-bg: #313030;
    --confirm-button-bg: #5f8afa;
    --confirm-button-bg-hover: #5aa6ff;
    --error: #db5555;
    --close-button-bg-color: #313030;
    --close-button-fill-icon-color: #c1c1c1;
    --spinner-color: #fff;
    --bottom-section: #131313;
    --mobile-text: #c1c1c1;
    --connected-green: #47e586;
  }
}

.nws-modal-wrapper {
  opacity: 0;
  visibility: hidden;
  color: var(--wallet-selector-text-color, var(--text-color));
  z-index: 100;
  justify-content: center;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-weight: 500;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nws-modal-wrapper .nws-modal-overlay {
  background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
  height: 100%;
  width: 100%;
  position: absolute;
}

.nws-modal-wrapper .nws-modal {
  background: var(--wallet-selector-content-bg, var(--content-bg));
  width: 100%;
  max-width: 812px;
  height: 555px;
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.6;
  transition: visibility linear, opacity .25s, -webkit-transform .25s, transform .25s;
  display: inline-flex;
  position: absolute;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.nws-modal-wrapper .nws-modal * {
  box-sizing: border-box;
}

.nws-modal-wrapper .nws-modal button {
  cursor: pointer;
  border: none;
  padding: .5em 1em;
}

.nws-modal-wrapper .nws-modal .modal-left {
  width: 35%;
  border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
  height: 100%;
  padding: 32px 24px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .modal-left::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper {
  margin-bottom: 20px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
  margin-top: 0;
  margin-bottom: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.single-wallet {
  width: 100%;
  cursor: pointer;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  padding: 12px;
  display: flex;
}

.single-wallet .icon {
  height: 48px;
  width: auto;
}

.single-wallet .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet .content {
  width: 50%;
}

.single-wallet .content .name {
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.single-wallet .content .description {
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.single-wallet .button-get {
  margin-left: auto;
  margin-right: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar {
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  column-gap: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover {
  background-color: var(--wallet-selector-wallet-option-bg-hover, var(--wallet-option-bg-hover));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  padding: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet .content .title {
  color: #fff;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet {
  opacity: .4;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .warning-triangle {
  display: flex;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border: 3px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet.connected-wallet .icon:before {
  border: 3px solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content {
  height: 32px;
  width: auto;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .description {
  display: none;
}

.nws-modal-wrapper .nws-modal .modal-right {
  width: 65%;
  padding: 32px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  margin-top: 91px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper-err {
  margin-top: 45px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
  padding: 0;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what {
  width: 100%;
  flex-flow: row;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what + .wallet-what {
  margin-top: 50px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
  width: 15%;
  padding-top: 9px;
  padding-bottom: 9px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side img {
  width: 100%;
  height: auto;
  max-height: 65px;
  border-radius: 6px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side {
  width: 100%;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3 {
  color: var(--wallet-selector-text-color, var(--heading-color));
  margin: 0 auto 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side p {
  word-break: break-word;
  width: 80%;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin: 4px auto;
  font-size: 18px;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-body.get-wallet-body {
  row-gap: 16px;
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 0;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  font: inherit;
  color: #fff;
  border-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border: .1em solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border-radius: 4px;
  margin: 25px auto 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
  background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
  color: #6494ee;
  border-width: 0;
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header {
  width: 100%;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
  cursor: pointer;
  height: 32px;
  background-color: var(--wallet-selector-close-button-bg-color, var(--close-button-bg-color));
  border: 0;
  border-radius: 50px;
  padding: 4px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  fill: var(--wallet-selector-close-button-fill-icon-color, var(--close-button-fill-icon-color));
}

.nws-modal-wrapper .nws-modal .back-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 4px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-header-wrapper {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button:active {
  background: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  pointer-events: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .action-buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .switch-network-message-wrapper .content .network-id {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper {
  padding: 0 26px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
  justify-content: center;
  margin-top: 53px;
  margin-bottom: 40px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description .specify-path {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  cursor: pointer;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
  justify-content: center;
  margin-top: 91px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .display-path {
  background: var(--home-button-bg);
  color: #606060;
  letter-spacing: 1px;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path {
  background: var(--change-path-bg);
  width: 63px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding: 0 5px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .path-value {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .path-description {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link {
  text-align: center;
  color: var(--selected-wallet-bg);
  cursor: pointer;
  font-size: 14px;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper button {
  width: 23px;
  background-color: var(--home-button-bg);
  border: none;
  padding: 0;
}

.nws-modal-wrapper .nws-modal .no-accounts-found-wrapper {
  margin-top: 50px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account {
  margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper {
  justify-content: center;
  margin-top: 90px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper input {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  border: 1px solid var(--confirm-button-bg-hover);
  border-radius: 50px;
  padding: 6px 8px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper p {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper {
  justify-content: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper button {
  color: var(--selected-wallet-bg);
  border: 1px solid var(--selected-wallet-bg);
  width: 78px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .form {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
  color: var(--text-color);
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account input[type="checkbox"] {
  width: 25px;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  padding: 10px;
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control label {
  color: inherit;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .action-buttons {
  justify-content: flex-end;
}

.nws-modal-wrapper .nws-modal .overview-wrapper p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .accounts {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
  color: #5f8afa;
  cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
  margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
  width: 40px;
  height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
  width: 100%;
  height: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: visibility linear, opacity .25s;
}

.nws-modal-wrapper .spinner {
  --size: 160px;
  --border: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.nws-modal-wrapper .spinner .icon {
  width: calc(var(--size) / 1.2);
  height: calc(var(--size) / 1.2);
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .05);
}

.nws-modal-wrapper .spinner img {
  width: 100%;
  height: auto;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nws-modal-wrapper .nws-modal-wrapper .nws-modal {
    width: 250px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list input {
    max-width: 140px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
    flex-direction: column;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control select {
    text-align: center;
  }
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #near-wallet img, .nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #math-wallet img, .nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #ledger img, .nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal .wallet-not-installed-wrapper .math-wallet img {
  -webkit-filter: invert();
  filter: invert();
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .spinner .icon {
  box-shadow: 0 10px 20px rgba(255, 255, 255, .05);
}

@media (prefers-color-scheme: dark) {
  .nws-modal-wrapper .nws-modal #near-wallet img, .nws-modal-wrapper .nws-modal #math-wallet img, .nws-modal-wrapper .nws-modal #ledger img, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper .math-wallet img {
    -webkit-filter: invert();
    filter: invert();
  }

  .nws-modal-wrapper .spinner .icon {
    box-shadow: 0 10px 20px rgba(255, 255, 255, .05);
  }
}

@-webkit-keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.connecting-wrapper {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.connecting-wrapper .content .icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.connecting-wrapper .content .icon img {
  width: 100%;
  height: auto;
}

.connecting-wrapper .content .connecting-name {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-details {
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-details span {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.connecting-wrapper .content .connecting-details .spinner {
  width: 25px;
  height: auto;
  -webkit-animation: spinner 2s linear infinite;
  animation: spinner 2s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection button {
  color: #6494ee;
  width: 86px;
  height: 40px;
  background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
  border: 0;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  margin: 24px auto;
  padding: 8px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper {
  vertical-align: middle;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error {
  color: #ce5a6f;
  vertical-align: middle;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper .error svg {
  color: var(--wallet-selector-content-bg, var(--content-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .success {
  color: #4fd98f;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  padding: 25px;
}

.connecting-wrapper .content .connecting-details {
  margin-top: 20px;
}

.connecting-wrapper .wallet-connected-success {
  align-items: center;
  display: flex;
}

.connecting-wrapper .wallet-connected-success svg {
  color: var(--content-bg);
}

.connecting-wrapper .wallet-connected-success span {
  color: var(--wallet-selector-connected-green, var(--connected-green));
  margin-left: 10px;
  font-size: 14px;
}

.single-wallet-get {
  width: 100%;
  max-width: 450px;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  margin: 20px auto auto;
  padding: 12px;
  display: flex;
}

.single-wallet-get .icon {
  min-width: 48px;
  max-height: 48px;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
  position: relative;
}

.single-wallet-get .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet-get .title {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.single-wallet-get .description {
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.single-wallet-get .button-get {
  margin-left: auto;
  margin-right: 0;
}

.connected-flag {
  color: var(--connected-green);
  border: 1px solid var(--connected-green);
  border-radius: 50px;
  align-items: center;
  padding: 3px 10px;
  display: flex;
}

.connected-flag:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border-radius: 50%;
  margin-right: 5px;
  display: block;
}

@media (min-width: 577px) {
  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile {
    display: none;
  }
}

@media (min-width: 769px) {
  .button-spacing {
    margin: 90px;
  }
}

@media (max-width: 768px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    margin-top: 45px;
  }

  .button-spacing {
    margin: 45px;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 40%;
    border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
    height: 100%;
    max-height: 70vh;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 60%;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    padding: 0 0 0 10px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
    margin-top: 20px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
    text-align: center;
  }

  .nws-modal-wrapper .wallet-not-installed-wrapper > p {
    max-width: 500px;
    margin: 20px 0 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
    background-color: var(--wallet-selector-home-button-bg, var(--content-bg));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-hide {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile p {
    text-align: center;
    margin: auto;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal {
    width: 100%;
    height: 500px;
    background: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    border-radius: 16px 16px 0 0;
    display: block;
    bottom: 0;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 100%;
    background-color: var(--wallet-selector-content-bg, var(--content-bg));
    height: auto;
    padding: 32px 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body {
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body .wallet-options-wrapper {
    margin: auto;
    display: flex;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 100%;
    background-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
    text-align: center;
    margin: 4px auto;
    font-size: 16px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .content {
    text-align: center;
    color: var(--mobile-text);
    margin: 0;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body {
    margin-top: 10px;
    padding: 0;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
    margin: 25px auto 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-header {
    text-align: center;
    font-size: 18px;
    display: block;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header h2 {
    text-align: center;
    font-size: 18px;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
    display: flex;
    overflow-x: auto;
  }

  .nws-modal-wrapper .nws-modal .info {
    width: 90px;
    display: none;
  }

  .single-wallet {
    width: 85px;
    display: block;
  }

  .single-wallet.sidebar .icon {
    width: 56px;
    height: 56px;
    margin: auto;
  }

  .single-wallet.sidebar .content {
    width: auto;
  }

  .single-wallet .content .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 10px;
    overflow: hidden;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
    margin-top: 0;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image, .nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description > p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: var(--backdrop-bg);
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: var(--close-button-fill-icon-color);
    border-radius: 10px;
  }
}

/*# sourceMappingURL=index.9d3baa68.css.map */
